
import { defineComponent, PropType, ref, watchEffect } from 'vue';
import { Status, Chip } from '../types';

export default defineComponent({
	props: {
		chip: {
			type: Object as PropType<Chip>,
			required: true,
		},
	},
	setup(props) {
		const detailClass = ref<string>('rounded');
		watchEffect(() => {
			const status = props.chip.status;
			if (status === Status.Loading) {
				detailClass.value = 'rounded loading';
				return;
			}
			if (status === Status.Ready) {
				detailClass.value = 'rounded ready';
				return;
			}
			if (status === Status.Ok) {
				detailClass.value = 'rounded ok';
				return;
			}
			if (status === Status.Fail) {
				detailClass.value = 'rounded fail';
				return;
			}
		});
		return { detailClass };
	},
});
