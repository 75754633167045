export default (arrayBuffer: ArrayBuffer, checkTimes = 188) => {
	let curIndex = 0;
	let isNotTSHeader = true;

	while (isNotTSHeader) {
		if (curIndex >= checkTimes) return 0;

		const header = new Uint8Array(arrayBuffer.slice(curIndex, curIndex + 4));

		curIndex++;

		const syncByte = header[0].toString(16);
		if (syncByte !== '47') continue;

		const transportErrorIndicator = ((header[1] & 0x80) >>> 7).toString(2);
		if (transportErrorIndicator !== '0') continue;

		// const payloadUnitStartIndicator = ((header[1] & 0x40) >>> 6).toString(2);
		// const transportPriority = ((header[1] & 0x20) >>> 5).toString(2);
		// const PID = (((header[1] & 0x1f) << 8) | header[2]).toString(16);

		const transportScramblingControl = ((header[3] & 0xc0) >>> 6).toString(2);
		if (transportScramblingControl === '01') continue;

		const adaptationFieldControl = ((header[3] & 0x30) >>> 4).toString(2);
		if (adaptationFieldControl === '00') continue;

		// const continuityCounter = (header[3] & 0x0f).toString(16);

		isNotTSHeader = false;
	}

	return curIndex - 1;
};
