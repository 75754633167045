// 用于目标URL转换成ts格式的URL
export default (targetURL: string, baseURL: string) => {
	baseURL = baseURL || location.href;
	let result = '';
	if (/^http/.test(targetURL)) {
		// 本身是ts地址，不需要转换
		result = targetURL;
	} else if (targetURL[0] === '/') {
		// 未知
		const domain = baseURL.split('/');
		result = domain[0] + '//' + domain[2] + targetURL;
	} else {
		// 将targetURL拼接在baseURL的尾部
		result = baseURL.replace(/\/[^/]*?$/, '/' + targetURL);
	}
	return result;
};
