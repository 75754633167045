
import { defineComponent, reactive, provide } from 'vue';
import Add from './components/Add.vue';
import List from './components/List.vue';
import { Task } from './types';

export default defineComponent({
	components: { Add, List },
	setup() {
		const taskList = reactive<Task[]>([]);
		provide('taskList', taskList);
	},
});
