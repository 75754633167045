import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-335c5ce9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lead mb-1" }
const _hoisted_2 = { class: "me-2 font-monospace" }
const _hoisted_3 = { class: "badge bg-warning ms-1" }
const _hoisted_4 = {
  class: "ms-2 font-monospace",
  style: {"font-size":"16px"}
}
const _hoisted_5 = { class: "progress mb-2" }
const _hoisted_6 = { class: "btn-group mb-1" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled", "id"]
const _hoisted_10 = ["for"]
const _hoisted_11 = ["disabled", "id"]
const _hoisted_12 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Detail = _resolveComponent("Detail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.task.name), 1),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.retryTask && _ctx.retryTask(...args))),
        class: _normalizeClass(_ctx.taskStatusClass)
      }, _toDisplayString(_ctx.task.status), 3),
      _withDirectives(_createElementVNode("span", _hoisted_3, "加密", 512), [
        [_vShow, _ctx.isShowEncryptedBadge]
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.hours) + ":" + _toDisplayString(_ctx.minutes) + ":" + _toDisplayString(_ctx.seconds), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", {
        class: "progress-bar progress-bar-striped bg-success",
        style: _normalizeStyle({
				width: Math.floor((_ctx.task.progress * 100) / _ctx.task.chipArray.length) + '%',
			})
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSPRBtn && _ctx.handleSPRBtn(...args))),
        disabled: _ctx.task.status !== _ctx.Status.Ready,
        class: "btn btn-primary btn-sm"
      }, _toDisplayString(_ctx.textOfSPRBtn), 9, _hoisted_7),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.retryAllChips && _ctx.retryAllChips(...args))),
        disabled: !_ctx.isNeedRetryChips,
        class: "btn btn-info btn-sm text-white"
      }, "重试错误片段", 8, _hoisted_8),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.remove(_ctx.task.id))),
        class: "btn btn-danger btn-sm"
      }, "删除"),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowDetail = !_ctx.isShowDetail)),
        class: "btn btn-secondary btn-sm"
      }, " 详情 "),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        disabled: _ctx.isStarted,
        class: "btn-check",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isUseMP4Format) = $event)),
        id: _ctx.task.id+'useMP4Format'
      }, null, 8, _hoisted_9), [
        [_vModelCheckbox, _ctx.isUseMP4Format]
      ]),
      _createElementVNode("label", {
        class: "btn btn-outline-dark btn-sm",
        for: _ctx.task.id+'useMP4Format'
      }, "将保存为 " + _toDisplayString(_ctx.isUseMP4Format ? "mp4" : "ts") + " 格式", 9, _hoisted_10),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        disabled: _ctx.isStarted,
        class: "btn-check",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isSaveWhileDownloading) = $event)),
        id: _ctx.task.id+'saveWhileDownloading'
      }, null, 8, _hoisted_11), [
        [_vModelCheckbox, _ctx.isSaveWhileDownloading]
      ]),
      _createElementVNode("label", {
        class: "btn btn-outline-success btn-sm",
        for: _ctx.task.id+'saveWhileDownloading'
      }, "已" + _toDisplayString(_ctx.isSaveWhileDownloading ? "开启" : "关闭") + "边下载边保存功能", 9, _hoisted_12),
      _withDirectives(_createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.saveChips && _ctx.saveChips(...args))),
        class: "btn btn-success btn-sm"
      }, "保存已下载片段", 512), [
        [_vShow, !_ctx.isSaveWhileDownloading]
      ])
    ]),
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.task.chipArray, (chip, i) => {
        return (_openBlock(), _createBlock(_component_Detail, {
          key: i,
          chip: chip,
          onClick: ($event: any) => (_ctx.retryChip(chip))
        }, null, 8, ["chip", "onClick"]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.isShowDetail]
    ])
  ], 64))
}