import { Status, Chip, Task, Aes } from '../types';
import { syncFetch } from './request';
import { AESDecryptor } from './aesDecryptor';
import muxjs from 'mux.js';
import applyURL from './applyURL';
import checkTSHeader from './checkTSHeader';

const decryptor = new AESDecryptor();

const convertToMP4Data = (data: ArrayBuffer, duration: number, offset: number) => {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		const transmuxer = new muxjs.mp4.Transmuxer({
			keepOriginalTimestamps: true,
			duration: Math.round(duration),
		});

		transmuxer.on('data', (seg: any) => {
			let afterData;
			if (offset === 0) {
				afterData = new Uint8Array(
					seg.initSegment.byteLength + seg.data.byteLength
				);
				afterData.set(seg.initSegment, 0);
				afterData.set(seg.data, seg.initSegment.byteLength);
				afterData = afterData.buffer;
			} else afterData = seg.data;

			resolve(afterData);
		});

		transmuxer.push(new Uint8Array(data));
		transmuxer.flush();
	});
};

export default (chip: Chip, task: Task) => {
	const taskFunc = async () => {
		try {
			chip.status = Status.Loading // 切换成正在下载的状态

			// 判断有无加密, 有的话就需要先去获取key
			let key = null;
			if (chip.aes) {
				const keyURI = applyURL(chip.aes.uri, task.url);
				// 获取key
				if (task.keys.has(keyURI)) key = task.keys.get(keyURI) as ArrayBuffer;
				else {
					key = (await syncFetch(keyURI, true)) as ArrayBuffer;
					task.keys.set(keyURI, key);
				}
			}

			// 获取ts片段的数据
			let data = (await syncFetch(chip.url, true)) as ArrayBuffer;

			// 有key的话就说明需要对data进行解密
			if (key) {
				decryptor.expandKey(key);
				data = decryptor.decrypt(data, 0, (chip.aes as Aes).iv);
			}

			// 校验并移除混淆字节
			const firstTSHeaderStartIndex = checkTSHeader(data);
			data = data.slice(firstTSHeaderStartIndex);

			// 转换为 mp4 格式
			if (task.isUseMP4Format)
				data = await convertToMP4Data(data, task.duration, chip.offset);

			chip.data = data;
			chip.status = Status.Ok;
			task.progress++;

			// 边下载边保存
			if (task.stream) {
				for (let i = task.stream.index; i < task.chipArray.length; i++) {
					const data = task.chipArray[i].data;
					if (data !== null) {
						task.stream.writer.write(new Uint8Array(data));
						task.chipArray[i].data = null;
						task.stream.index++;
					} else break;
				}
				if (task.stream.index >= task.chipArray.length)
					task.stream.writer.close();
			}

		} catch (error) {
			console.error(error);
			chip.status = Status.Fail;
		}
	};
	taskFunc.id = task.id;
	return taskFunc;
};
