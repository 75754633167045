
import { defineComponent, inject, ref } from 'vue';
import TaskComp from './Task.vue';

import { Status, Chip, Task } from '../types';

import Manager from '../util/manager';
import createChipTask from '../util/createChipTask';

export default defineComponent({
	components: { TaskComp },
	methods: {
		adjustWorkerCount() {
			if (typeof this.workerCount === 'number')
				this.manager.adjust(this.workerCount);
			else {
				this.workerCount = 10;
				this.manager.adjust(10);
			}
		},
	},
	setup() {
		const taskList = inject('taskList') as Task[];
		const workerCount = ref(10);
		const manager = new Manager(workerCount.value); // 创建片段下载管理

		// 暂停和开始管理器
		const isRunning = ref(true);
		const changeWorkerStatus = () => {
			if (isRunning.value) manager.stopWorkers();
			else manager.runWorkers();
			isRunning.value = !isRunning.value;
		};

		// 删除任务
		const remove = (id: number) => {
			manager.remove(id);
			const index = taskList.findIndex(task => task.id === id);
			const task = taskList[index];
			if (task.stream) task.stream.writer.abort();
			task.chipArray.forEach(chip => (chip.data = null));
			taskList.splice(index, 1);
		};

		// 重新下载所有错误片段
		const isRetring = ref(false);
		let timer: number | undefined = undefined;
		const autoRetryAll = () => {
			if (!isRetring.value)
				timer = setInterval(() => {
					taskList.forEach((task: Task) => {
						task.chipArray.forEach((chip: Chip) => {
							if (chip.status === Status.Fail) {
								chip.status = Status.Ready;
								manager.submit(createChipTask(chip, task));
							}
						});
					});
				}, 2000);
			else clearInterval(timer);
			isRetring.value = !isRetring.value;
		};

		window.onunload = () => {
			taskList.forEach(task => {
				if (task.stream) task.stream.writer.abort();
			});
		};

		window.onbeforeunload = evt => {
			if (manager.isWorking()) evt.returnValue = 'Are you sure you want to leave?';
		};

		return {
			taskList,
			workerCount,
			manager,
			isRunning,
			isRetring,
			changeWorkerStatus,
			autoRetryAll,
			remove,
		};
	},
});
