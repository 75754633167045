import { WritableStream } from 'streamsaver';

export enum Status {
	Loading = 'Loading',
	Ready = 'Ready',
	Ok = 'OK',
	Fail = 'Fail',
}

export type Aes = {
	method: string;
	uri: string;
	iv: ArrayBuffer;
};

export interface Chip {
	url: string;
	status: Status;
	data: ArrayBuffer | null;
	aes: Aes | null;
	offset: number;
}

export interface Task {
	id: number;
	name: string;
	url: string;
	content: string;
	status: Status;
	progress: number;
	chipArray: Array<Chip>;
	duration: number;
	keys: Map<string, ArrayBuffer>;
	isUseMP4Format: boolean;
	stream?: {
		writer: {
			write: (data: Uint8Array) => void;
			close: () => void;
			abort: () => void;
		};
		index: number;
	};
}
