interface Callback {
	success?: (data: ArrayBuffer | string) => any;
	fail?: (error: Error) => any;
}
// 使用fetch来请求数据
export default (url: string, callback: Callback, isReturnArraybuffer = false) => {
	fetch(url)
		.then((res): Promise<ArrayBuffer | string> => {
			if (isReturnArraybuffer) return res.arrayBuffer();
			return res.text();
		})
		.then(data => callback.success && callback.success(data))
		.catch(error => callback.fail && callback.fail(error));
};

// 同步请求数据
export const syncFetch = async (url: string, isReturnArraybuffer = false) => {
	return await fetch(url).then((res): Promise<ArrayBuffer | string> => {
		if (isReturnArraybuffer) return res.arrayBuffer();
		return res.text();
	});
};
