
import { defineComponent, ref, inject } from 'vue';
import { Task, Status } from '../types';

export default defineComponent({
	methods: {
		async upload() {
			const fileHandle = (await (window as any).showOpenFilePicker())[0];
			const file = await fileHandle.getFile();
			if (!/.m3u8$/.test(file.name)) {
				window.alert('请上传 m3u8 格式的文件!');
				return;
			}
			this.filename = file.name;
			this.fileContent = await file.text();
		},
		addTaskByURL()  {
			const id = ++this.taskIndex;
			const now = new Date();
			const task: Task = {
				id: id,
				name: this.name || `任务${id}_${now.getMonth() + 1}-${now.getDate()}`,
				url: this.url,
				content: '',
				status: Status.Loading,
				progress: 0,
				duration: 0,
				chipArray: [],
				keys: new Map(),
				isUseMP4Format: true,
			};
			this.taskList.push(task);
			this.clearInputs()
		},
		addTaskByFile(){
			const id = ++this.taskIndex;
			const task: Task = {
				id: id,
				name: this.name || this.filename.slice(0, -5),
				url: '',
				content: this.fileContent,
				status: Status.Loading,
				progress: 0,
				duration: 0,
				chipArray: [],
				keys: new Map(),
				isUseMP4Format: true,
			};
			this.taskList.push(task);
			this.clearInputs()
		},
		clearInputs(){
			this.name = '';
			this.filename = '';
			this.fileContent = '';
			this.url = '';
		}
	},
	setup() {
		const taskList: Task[] = inject<any>('taskList');
		const taskIndex = ref(0)

		const name = ref('');
		const url = ref('');
		const fileContent = ref('');
		const filename = ref('')

		return { taskList, taskIndex, url, name, fileContent, filename };
	},
});
