// 利用a标签的下载功能将视频保存到本地
export default (fileDataList: Array<ArrayBuffer> | undefined, fileName: string, isUseMP4Format = true) => {
	let fileBlob;
	let name;
	if(isUseMP4Format){
		fileBlob = new Blob(fileDataList, { type: 'video/mp4' });
		name = fileName + '.mp4';
	}else{
		fileBlob = new Blob(fileDataList, { type: 'video/MP2T' });
		name = fileName + '.ts';
	}

	const a = document.createElement('a');
	a.download = name;
	a.href = URL.createObjectURL(fileBlob);
	a.style.display = 'none';
	document.body.appendChild(a);
	a.click();
	a.remove();
};
