
import {
	defineComponent,
	onMounted,
	PropType,
	reactive,
	ref,
	toRefs,
	watchEffect,
} from 'vue';
import Detail from './Detail.vue';
import { Task, Status, Chip } from '../types';
import request from '../util/request';
import applyURL from '../util/applyURL';
import storeChips from '../util/storeChips';
import createChipTask from '../util/createChipTask';
import Manager from '../util/manager';
import { Parser } from 'm3u8-parser';
import { getDefaultIVArrayBuffer } from '../util/aesDecryptor';
import streamsaver from 'streamsaver'

export default defineComponent({
	components: { Detail },
	props: {
		task: { type: Object as PropType<Task>, required: true },
		manager: { type: Object as PropType<Manager>, required: true },
		remove: { type: Function, required: true },
	},
	computed: {
		textOfSPRBtn(){
			if(this.task.status === Status.Ok) return '下载完成'
			if(!this.isStarted) return '开始下载'
			if(this.isRunning) return '暂停下载'
			return '恢复下载'
		},
		isNeedRetryChips(){
			if (this.task.chipArray.some(chip => chip.status === Status.Fail))
				return true
			return false
		}
	},
	setup(props) {
		const { task, manager } = toRefs(props);
		const m3u8Parser = new Parser();
		const hours = ref(0);
		const minutes = ref(0);
		const seconds = ref(0);
		const taskStatusClass = ref(''); // 控制任务的状态显示
		const isShowEncryptedBadge = ref(false); // 显示任务是否加密
		const isShowDetail = ref(false); // 控制任务的详情显示
		const isUseMP4Format = ref(true) // 是否保存为 mp4 格式
		const isSaveWhileDownloading = ref(streamsaver.supported) // 是否开启边下载边保存功能
		const isStarted = ref(false) // 是否已经开始下载
		const isRunning = ref(false) // 是否正在下载

		// 完善任务信息
		const getTaskDetail = () => {
			const rawM3u8Data = task.value.content;

			// 解析m3u8文件内容并获取片段信息
			m3u8Parser.push(rawM3u8Data);
			m3u8Parser.end();
			const m3u8 = m3u8Parser.manifest;
			const segments = m3u8.segments as any[];

			// 如果无ts片段, 则显示失败
			if (segments.length === 0) {
				task.value.status = Status.Fail;
				console.error(`任务${task.value.name}没有检测到ts片段!`);
				return;
			}

			// 计算时长
			const totalDuration = segments.reduce(
				(prevDuration, seg) => prevDuration + seg.duration || 0,
				0
			);
			task.value.duration = totalDuration;
			hours.value = Math.floor(totalDuration / 3600);
			minutes.value = Math.floor((totalDuration - hours.value * 3600) / 60);
			seconds.value = Math.ceil(
				totalDuration - hours.value * 3600 - minutes.value * 60
			);

			// 检查是否存在AES加密
			if (rawM3u8Data.indexOf('#EXT-X-KEY') > -1) isShowEncryptedBadge.value = true;

			// 获取ts片段信息
			segments.forEach((seg, offset) => {
				const chip: Chip = reactive({
					url: applyURL(seg.uri, task.value.url),
					status: Status.Ready,
					data: null,
					offset,
					aes: seg.key ? { method: seg.key.method || '',
										uri: seg.key.uri || '',
										iv: seg.iv ? seg.iv.buffer : getDefaultIVArrayBuffer(offset),
										key: null }: null,
				});
				task.value.chipArray.push(chip);
			});

			// 任务已经准备好了
			task.value.status = Status.Ready;
		};

		// 准备好开始下载ts片段的一切前提: 获取m3u8文件内容, 计算时长, 获取片段信息等
		const ready = async () => {
			if (task.value.content) getTaskDetail();
			else
				request(task.value.url, {
					success(data) {
						const rawContent = data as string;
						task.value.content = rawContent;
						getTaskDetail();
					},
					fail() {
						task.value.status = Status.Fail;
					},
				});
		};

		// 任务创建后马上进行准备
		onMounted(ready);

		// 当任务准备失败后, 重新进行准备
		const retryTask = () => {
			if (task.value.status === Status.Fail) {
				task.value.status = Status.Loading;
				ready();
			}
		};

		// 开始下载ts片段
		const startTask = () => {
			isStarted.value = true
			isRunning.value = true

			// 是否保存为 mp4 格式
			task.value.isUseMP4Format = isUseMP4Format.value

			// 是否边下载边保存
			if(isSaveWhileDownloading.value){
				streamsaver.mitm = "./mitm.html"
				const filename = task.value.name + (isUseMP4Format.value ? '.mp4' : '.ts')
				task.value.stream = {
					writer: streamsaver.createWriteStream(filename).getWriter(),
					index: 0,
				}
			}

			// 向管理器提交下载ts片段的任务
			task.value.chipArray.forEach(chip =>
				manager.value.submit(createChipTask(chip, task.value))
			);
		};

		// 暂停任务
		const pauseTask = () => {
			isRunning.value = false
			props.manager.pauseTask(task.value.id)
		}

		// 恢复任务
		const restoreTask = () => {
			isRunning.value = true
			props.manager.restoreTask(task.value.id)
		}

		const handleSPRBtn = () => {
			if(!isStarted.value) startTask()
			else if(isRunning.value) pauseTask()
			else restoreTask()
		}

		// 重试某个错误片段
		const retryChip = (chip: Chip) => {
			if (chip.status === Status.Fail) {
				chip.status = Status.Ready;
				props.manager.submit(createChipTask(chip, task.value));
			}
		};

		// 重试所有错误片段
		const retryAllChips = () => {
			task.value.chipArray.forEach(chip => retryChip(chip))
		};

		// 保存已下载的片段
		const saveChips = () => {
			storeChips(
				task.value.chipArray.map(chip => chip.data as ArrayBuffer),
				task.value.name,
				task.value.isUseMP4Format,
			);
		};

		// 监视任务状态
		watchEffect(() => {
			const status = task.value.status;
			const chipArray = task.value.chipArray;
			if (status === Status.Ready) {
				taskStatusClass.value = 'badge bg-primary';
				if (
					chipArray.length > 0 &&
					chipArray.every(chip => chip.status === Status.Ok)
				) {
					task.value.status = Status.Ok;
					taskStatusClass.value = 'badge bg-success';
					isRunning.value = false
					if(!isSaveWhileDownloading.value) saveChips();
				}
				return;
			}
			if (status === Status.Fail) {
				taskStatusClass.value = 'badge bg-danger cursor';
				return;
			}
			if (status === Status.Loading) {
				taskStatusClass.value = 'badge bg-secondary';
				return;
			}
		});

		return {
			handleSPRBtn,
			saveChips,
			retryChip,
			retryAllChips,
			retryTask,
			isStarted,
			isRunning,
			isShowDetail,
			isShowEncryptedBadge,
			isUseMP4Format,
			isSaveWhileDownloading,
			taskStatusClass,
			hours,
			minutes,
			seconds,
			Status,
		};
	},
});
