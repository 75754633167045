interface TaskType {
	(): Promise<void>;
	id: number;
}

let taskQueue: Array<TaskType> = [];
const pausedTaskIDs: Array<number> = [];

class Worker {
	_timer: number | undefined = undefined;
	isPaused = false;
	constructor() {
		this._work();
	}
	async _work() {
		if (this.isPaused) return;
		if (taskQueue.length > 0) {
			const task = taskQueue.shift() as TaskType;
			if (pausedTaskIDs.findIndex(v => v === task.id) >= 0) {
				taskQueue.push(task);
				this._wait();
			} else {
				await task();
				this._work();
			}
		} else this._wait();
	}
	_wait(seconds = 1) {
		clearTimeout(this._timer);
		this._timer = setTimeout(() => {
			this._work();
		}, 1000 * seconds);
	}
	_run() {
		clearTimeout(this._timer);
		this.isPaused = false;
		this._work();
	}
	_stop() {
		clearTimeout(this._timer);
		this.isPaused = true;
	}
}

class Manager {
	workerCount: number;
	workers: Worker[] = [];
	constructor(workerCount = 10) {
		this.workerCount = workerCount;
		for (let i = this.workerCount; i--; ) this.workers.push(new Worker());
	}
	submit(task: TaskType) {
		taskQueue.push(task);
	}
	pauseTask(id: number) {
		pausedTaskIDs.push(id);
	}
	restoreTask(id: number) {
		const idx = pausedTaskIDs.findIndex(v => v === id);
		pausedTaskIDs.splice(idx, 1);
	}
	stopWorkers() {
		this.workers.forEach(worker => worker._stop());
	}
	runWorkers() {
		this.workers.forEach(worker => worker._run());
	}
	remove(id: number) {
		taskQueue = taskQueue.filter(task => task.id !== id);
	}
	adjust(workerCount: number) {
		if (workerCount <= 0) return;
		if (this.workers.length === workerCount) return;
		this.workerCount = workerCount;
		if (workerCount > this.workers.length) {
			const count = workerCount - this.workers.length;
			for (let i = count; i--; ) {
				this.workers.push(new Worker());
				console.info('A worker joins.');
			}
		} else {
			const count = this.workers.length - workerCount;
			for (let i = count; i--; ) {
				const worker = this.workers.pop() as Worker;
				worker._stop();
				console.info('A worker leaved.');
			}
		}
	}
	isWorking() {
		return taskQueue.length > 0;
	}
}

export default Manager;
